<template>
  <div>
    <h2>You are not authorized to access that page!</h2>
    <br />
    <h3>Your access token might have expired.</h3>
    <h3>To force a new token, click the following <a href="/logout">link</a></h3>
  </div>
</template>

<script>
export default {
  name: 'unauthorized'
};
</script>
